<template>
  <div>
    <!-- 新增收货人信息 -->
    <div class="add-address" v-show="showAddDiv">
      <div class="person-header">
        <p><strong>新增收货人地址</strong></p>
      </div>
      <div class="person-bottom">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="收货人姓名" prop="fullname">
            <el-input style="width:400px" v-model="ruleForm.fullname" placeholder="长度不超过25个字符"></el-input>
          </el-form-item>
          <el-form-item label="收货人电话" prop="mobile">
            <el-input style="width:400px" v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="邮政编码" prop="zip_code">
            <el-input style="width:400px" v-model="ruleForm.zip_code" placeholder="请填写邮编"></el-input>
          </el-form-item>
          <el-form-item label="地址信息" prop="region_id">
            <Region style="width:400px" @reginCallBack="reginBack"></Region>
          </el-form-item>
          <el-form-item label="详细信息" prop="address">
            <el-input style="width:400px" v-model="ruleForm.address"
                      placeholder="请输入详细地址信息，如道路、门牌号、小区、楼栋号、单元等信息"></el-input>
          </el-form-item>
          <el-checkbox true-label="y" false-label="n" v-model="ruleForm.is_default">设置默认收货地址</el-checkbox>
          <br/>
          <div class="primary-box">
            <el-button @click="cancel" style="width:150px;margin-right: 50px" size="small">取消</el-button>
            <el-button type="primary" class="primary" style="width:150px" size="small" @click="submitForm('ruleForm')">
              立即创建
            </el-button>
          </div>
        </el-form>
      </div>
    </div>

    <div class="add-address" v-show="showEditDiv">
      <div class="person-header">
        <p><strong>修改收货人地址</strong></p>
      </div>
      <div class="person-bottom">
        <el-form :model="editForm" :rules="rules" ref="editForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="收货人姓名" prop="fullname">
            <el-input style="width:400px" v-model="editForm.fullname" placeholder="长度不超过25个字符"></el-input>
          </el-form-item>
          <el-form-item label="收货人电话" prop="mobile">
            <el-input style="width:400px" v-model="editForm.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="邮政编码" prop="zip_code">
            <el-input style="width:400px" v-model="editForm.zip_code" placeholder="请填写邮编"></el-input>
          </el-form-item>
          <el-form-item label="地址信息" prop="region_id">
            <Region ref="RegionVisble" style="width:400px" @reginCallBack="reginBack"></Region>
          </el-form-item>
          <el-form-item label="详细信息" prop="address">
            <el-input style="width:400px" v-model="editForm.address"
                      placeholder="请输入详细地址信息，如道路、门牌号、小区、楼栋号、单元等信息"></el-input>
          </el-form-item>
          <el-checkbox true-label="y" false-label="n" v-model="editForm.is_default">设置默认收货地址</el-checkbox>
          <br/>
          <div class="primary-box">
            <el-button @click="cancel" style="width:150px;margin-right: 50px" size="small">取消</el-button>
            <el-button type="primary" class="primary" style="width:150px" size="small" @click="submitForm('editForm')">
              保存地址
            </el-button>
          </div>
        </el-form>
      </div>
    </div>

    <!-- 我的收货地址 -->
    <div class="add-info" v-show="showTable">
      <div class="person-headers">
        <p>
          <strong>我的收货地址</strong>
          <el-link type="primary" style="float:right;right: 20px" @click="showAdd"> 新增地址</el-link>
        </p>
      </div>

      <div style="padding: 20px">
        <el-table
            :data="tableData"
            border style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              height: '36px',
              color: '#000',
              'font-size': '14px',
              background: '#f4f4f4',
            }"
                  :cell-style="{
              'text-align': 'center',
              height: '50px',
              color: '#575757',
              'font-size': '12px',
            }"
            stripe>
          <el-table-column prop="fullname" label="收件人姓名" width="120"></el-table-column>
          <el-table-column prop="zip_code" label="邮编" width="80"></el-table-column>
          <el-table-column prop="mobile_format" label="手机号码" width="120"></el-table-column>
          <el-table-column prop="address" label="详细地址"></el-table-column>
          <el-table-column prop="is_default" label="是否默认" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.is_default == 'y'">是</span>
              <span v-if="scope.row.is_default == 'n'">否</span>
            </template>
          </el-table-column>
          <el-table-column prop="is_default" label="操作" width="160">
            <template slot-scope="scope">
              <el-link type="primary" icon="el-icon-edit" style="font-size:12px" @click="toSave( scope.row )">修改
              </el-link>
              <el-divider direction="vertical"></el-divider>
              <el-link type="danger" icon="el-icon-delete" style="font-size:12px" @click="toDelete(scope.row.id)">删除
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import Region from '@/components/Region/Region';
import {getUserAddress, createUserAddress, updateUserAddress, deleteUserAddress} from '@/api/user';

export default {
  components: {
    Region
  },
  data() {
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写收获人电话'));
      }
      let reg = /^1([0-9])\d{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error('手机号格式错误'));
      }
    };
    return {
      tableData: [],
      ruleForm: {
        address: '',
        region_id: '',
        zip_code: '',
        fullname: '',
        mobile: '',
        is_default: 'n'
      },
      editForm: {},
      rules: {
        /* 地址信息 */
        region_id: [{required: true, message: '请选择地', trigger: 'blur'}],
        /*详细地址信息  */
        address: [{required: true, message: '请输入详细地址', trigger: 'blur'}],
        /* 邮政编码 */
        zip_code: [{required: false, message: '请输入邮政编码', trigger: 'blur'}],
        fullname: [{required: true, message: '请输入收货人姓名', trigger: 'blur'}],
        mobile: [{required: true, message: '请输入收货人手机号码', trigger: 'blur'}, {validator: checkMobile, trigger: 'blur'}]
      },
      showAddDiv: false,
      showEditDiv: false,
      showTable: true
    };
  },
  mounted() {
    this.loadAddress();
  },
  methods: {
    async loadAddress() {
      const res = await getUserAddress();
      if (res.statusCode == 200) {
        this.tableData = res.data;
      }
    },
    async createAddress() {
      const res = await createUserAddress(this.ruleForm);
      if (res.statusCode == 200) {
        this.$notify({
          message: '添加成功',
          type: 'success'
        });
        this.loadAddress();
        this.ruleForm = {};
        this.showAddDiv = false
        this.showTable = true
      } else {
        this.$notify({
          message: res.message,
          type: 'error'
        });
      }
    },
    async saveAddress() {
      const res = await updateUserAddress(this.editForm);
      if (res.statusCode == 200) {
        this.$notify({
          message: '修改成功',
          type: 'success'
        });
        this.loadAddress();
        this.editForm = {};
        this.showEditDiv = false
        this.showTable = true
      } else {
        this.$notify({
          message: res.message,
          type: 'error'
        });
      }
    },
    async deleteAddress(data) {
      const res = await deleteUserAddress(data);
      if (res.statusCode == 200) {
        this.$notify({
          message: '删除成功',
          type: 'success'
        });
        this.loadAddress();
      } else {
        this.$notify({
          message: res.message,
          type: 'error'
        });
      }
    },
    showAdd() {
      this.showAddDiv = true
      this.showTable = false
      this.showEditDiv = false
    },
    toDelete(id) {
      var data = {address_id: id};
      this.$confirm('此操作将永久删除该地址?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.deleteAddress(data);
          })
          .catch(() => {
          });
    },
    toSave(row) {
      this.editForm = row
      this.$refs.RegionVisble.checkedVal(this.editForm.region_id);
      this.showEditDiv = true
      this.showTable = false
      this.showAddDiv = false
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (formName == 'editForm') {
            this.saveAddress();
          } else {
            this.createAddress();
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    reginBack(region) {
      this.ruleForm.region_id = region[2];
    },
    cancel(){
      this.showTable = true
      this.showEditDiv = false
      this.showAddDiv = false
    }
  }
};
</script>

<style lang="less" scoped>
.add-address {
  width: 940px;
  min-height: 940px;
  background: #ffffff;
}

.person-headers {
  height: 76px;
  width: 940px;
  border-bottom: 1px solid #b5b5b5;
  background-color: #fff;

  p {
    padding: 40px 0 0 40px;
  }
}

.add-info {
  width: 940px;
  min-height: 940px;
  background: #ffffff;
}

/deep/ .demo-ruleForm {
  margin-top: 30px;
  margin-left: 80px;

  .primary-box {
    width: 520px;
    padding: 40px 0px;
    display: flex;
    justify-content: flex-end;
  }
}

/deep/ input[placeholder] {
  font-size: 12px;
  color: #575757;
}

/deep/ .el-checkbox {
  margin-left: 120px;
  color: #575757;
}

/deep/ .el-button {
  border-radius: 0;
}

/deep/ .el-table tr {
  height: 45px;
}
</style>
